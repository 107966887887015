import axios, { AxiosRequestConfig } from 'axios';
import { checkAccessToken, getAccessToken, renewAccessToken, saveAccessToken } from '../services/authService';
import { AxiosError } from 'axios';
import { IAccessToken } from '../types/authTypes';

export const axiosV1 = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  headers: {'Content-Type': 'application/json'}
})

export const axiosV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2,
  headers: {'Content-Type': 'application/json'}
})

axiosV1.interceptors.request.use(applyConfig);
axiosV2.interceptors.request.use(applyConfig);

async function applyConfig(config: AxiosRequestConfig) {
  if (config.url === '/login' || config.url === '/auth/token') {
    return config;
  }

  try {
    const authTokenIsValid = await checkAccessToken();

    if (!authTokenIsValid) {
      const accessToken:IAccessToken = await renewAccessToken();
      saveAccessToken(accessToken);
    }
  } catch (err) {
    const authError: AxiosError = {
      name: 'Auth Error',
      config,
      code: '401',
      message: err.message
    };

    return Promise.reject(authError);
  }

  config.headers['Authorization'] = `Bearer ${getAccessToken().token}`;

  return config;
}

export default axiosV1;

